import { useEffect } from 'react';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom'

const useInterceptor = (refreshToken) => {
    //const navigate = useNavigate();
    
    useEffect(() => {
        // Create the request interceptor
        const requestInterceptor = (token) => axios.interceptors.request.use(
            (config) => {
                if (token) {
                    //Attach token to the Authorization header
                    config.headers['Authorization'] = `Bearer ${token}`;
                }

                return config;
            },
            (error) => {
                //Handle errors
                return Promise.reject(error);
            }
        );

        // Create the response interceptor
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response?.status === 401  && !originalRequest._retry) { 
                    originalRequest._retry = true;                 
                    try {
                        const newToken = await refreshToken();
                        originalRequest.headers.Authorization = `Bearer ${newToken}`;
                        return axios(originalRequest);
                      } 
                      catch (refreshError) {
                        return Promise.reject(refreshError);
                      }
                }
                return Promise.reject(error);
            }
        );

        // Cleanup function to remove interceptors when component unmounts
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return axios; // Return the axios instance if needed for custom configuration
};

export default useInterceptor;