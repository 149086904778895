import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import loadable from '@loadable/component';
import App from './App';
import {NAV_SERVICES, NAV_GOVCOUNCIL, NAV_MODEL, NAV_SUPPORT, NAV_ROADMAP} from './../src/shared/constants';

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const Dashboard = loadable(() => import('./routes/dashboard/dashboard'));
//const Metrics = loadable(() => import('./routes/metrics/metrics'));
const Default = loadable(() => import('./routes/default/default'));
const NotFound = loadable(() => import('./notfound'));
const Callback = loadable(() => import('./callback/_dropp'));
const Revenue = loadable(() => import('./routes/charts/components/revenue'));
const NetworkData = loadable(() => import('./routes/charts/components/network'));
const StakingEconomics = loadable(() => import('./routes/charts/components/staking'));
const Defi = loadable(() => import('./routes/charts/components/defi'));
const HbarDist = loadable(() => import('./routes/charts/components/distribution'));
const Analytics = loadable(() => import('./routes/analytics/analytics'));
const Roadmap = loadable(() => import('./routes/roadmap/roadmap'));
const Blog = loadable(() => import('./routes/blog/blog'));
const Support = loadable(() => import('./routes/support/support'));
const GC = loadable(() => import('./routes/gc/gc'));
const Services = loadable(() => import('./routes/account/services/services'));
const Unsubscribe = loadable(() => import('./unsubscribe'));
const Unauthorized = loadable(() => import('./../src/routes/unauthorized'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route 
            path="/" 
            element={<App urlParams={ urlParams } />} >
            <Route index element={<Default title="Default" />} />
            <Route path="home" element={<Default title="Default" />} />
            <Route path="dashboard"element={<Dashboard title="Dashboard" />} />
            {/* <Route path="metrics"element={<Metrics title="Metrics" />} /> */}
            <Route path="networkdata" element={<NetworkData title={`Network Data`} />} />
            <Route path="revenue" element={<Revenue title={`Revenue`}  />} />
            <Route path="defionhedera" element={<Defi title={`Defi On Hedera`}  />} />
            <Route path="stakingeconomics" element={<StakingEconomics title={`Staking Economics`}  />} />
            <Route path="hbardistribution" element={<HbarDist title={`HBAR Distribution`}  />} />
            <Route path="valuationmodel" element={<Analytics title={NAV_MODEL} />} />
            <Route path="insights" element={<Blog title="The Enlightened Hbarbarian" />} />
            <Route path="governingcouncil" element={<GC title={NAV_GOVCOUNCIL} />} />
            <Route path="roadmap" element={<Roadmap title={NAV_ROADMAP} />} />
            <Route path="notfound" element={<NotFound />} />
            <Route path="support" element={<Support title={NAV_SUPPORT} />} />
            <Route path="services" element={<Services title={NAV_SERVICES} />} />
            <Route path="unsubscribe" element={ <Unsubscribe />} />
            <Route path="unauthorized" element={ <Unauthorized />}/>
            <Route path="*" element={ <NotFound />} />
            <Route path="callback" element={ <Callback />} />
        </Route>
    )
  );

  const Entrypoint = () => {
    return (
        root.render(            
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </React.StrictMode>            
        )
    )
  }

  Entrypoint();

//if ('serviceWorker' in navigator) {
    //// Register a service worker hosted at the root of the
    // site using the default scope.
    //navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
    //  registration => {
    //    console.log('Service worker registration succeeded:', registration);
    //  },
    //  /*catch*/ error => {
    //    console.error(`Service worker registration failed: ${error}`);
    //  }
    //);
//} else {
//    console.error('Service workers are not supported.');
//}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
