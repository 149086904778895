import axios from 'axios';
import { getTimestamp, getBrowserIdentity, objectToQs, replaceAll } from './../shared/fn';

const funcsDir = () => {
    return '/.netlify/functions/';
}

const mirrorNodeUrl = (env) => {
    if(!env) env = 'mainnet';
    return `https://${env}.mirrornode.hedera.com/api/v1/`;
}


export const getTPS = async (env) => {
    try {
        const envUrl = mirrorNodeUrl(env);
        const url = `${envUrl}blocks?limit=1`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info('axios error - getTPS');
        return e;
    }
}

export const getNetworkNode = async (gtNodeId, order) => {
    const endpoint = `network/nodes?limit=1&node.id=eq:${gtNodeId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkNodes = async (gtNodeId, order) => {
    const nodeStr = gtNodeId===0 ? `&node.id=gte:${gtNodeId}` : `&node.id=gt:${gtNodeId}`;
    const endpoint = `network/nodes?limit=25&order=${order}${nodeStr}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkStake = async () => {
    const endpoint = `network/stake`;
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getAccount = async (accountId) => {
    const endpoint = `accounts/${accountId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getAccountBalance = async (accountId) => {
    const endpoint = `balances?account.id=${accountId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkSupply = async () => {
    const endpoint = `network/supply`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const fetchEndpoint = async (endpt) => {
    try {
        const url=`${mirrorNodeUrl() + endpt}`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info(e);
        return e;
    }
}

export const fetchLocalData = async (name) => {
    try {
        const url=`${name}.json`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info(e);
        //return e;
    }
}

export const getView = async(view) =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=${view}`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getStakingData = async() =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=stakingdata`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getHbarMeta = async() =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=hbarmeta`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getHbarDist = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=hbardist`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getAppSettings = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=appsettings`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getAdminData = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=admindata`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getVipAccess = async (code) => {
    code = encodeURIComponent(code);

    const res=axios(`${funcsDir()}access_get?code=${code}`)
        .then(function (response) {
            return response.data.response[0];
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const updateUserVipAccess = async(userAccessId, shard, realm, num) => {
    const resp=await axios(`${funcsDir()}access_update?useraccessid=${userAccessId}&shard=${shard}&realm=${realm}&num=${num}`);    
    return resp;
}

export const deleteVipAccess = async(invitationId, email, sessionState) => {
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;
    let encoded = encodeURIComponent(email);

    const resp=await axios(`${funcsDir()}access_delete?invitationid=${invitationId}&uniqueid=${uniqueId}&sessionid=${sessionId}&email=${encoded}`);    
    return resp.data.response;
}

export const upsertInvitation = async(invitation, sessionState) => {
    const invitationId = invitation.invitationid ?? '';
    const email = encodeURIComponent(invitation.email);
    const firstname = invitation.firstname;
    const lastname = invitation.lastname;
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;

    //console.log('session sessionState', sessionState.data);
    let paramStr =`invitation_upsert?invitationid=${invitationId}&email=${email}&firstname=${firstname}&lastname=${lastname}&uniqueid=${uniqueId}&sessionid=${sessionId}`;
    let resp
    resp=await axios(`${funcsDir()}${paramStr}`)
         .then(function (response) {
             return response;
    })

    return resp.data.response;
}

export const deleteMessageTemplate = async(messageTemplateId, sessionState) => {
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;

    const resp=await axios(`${funcsDir()}message_template_delete?messagetemplateid=${messageTemplateId}&uniqueid=${uniqueId}&sessionid=${sessionId}`);    
    return resp.data.response;
}

export const upsertGcNodes = async(record) => {
    const nodeId = record.nodeid ?? '';
    const owner = record.owner;
    const city = record.city;
    const iso = record.iso3;
    const geoLocationId=record.geolocationid;
    const sectorId = record.sectorid;
    const coordinates=record.coordinates;
    const dateJoined = new Date(record.datejoined).getTime()*1000000;
    const dateLeft = record.dateleft ? new Date(record.dateleft).getTime()*1000000 : '';
    const nodenum =record.nodenum;
    const isActive=record.isactive ? 'true' : 'false';
    
    const resp=await axios(`${funcsDir()}node_upsert?nodeid=${nodeId}&owner=${owner}&city=${city}&iso=${iso}&geolocationid=${geoLocationId}&sectorid=${sectorId}&coordinates=${coordinates}&datejoined=${dateJoined}&dateleft=${dateLeft}&nodenum=${nodenum}&isactive=${isActive}`)
        .then(function (response) {
            return response;
    })

    return resp.data.response;
}

export const deleteGcNode = async(nodeId, member) => {
    const permissionId = member.permissions[0];

    const resp=await axios(`${funcsDir()}node_delete?nodeid=${nodeId}&permissionid=${permissionId}`);    
    return resp.data.response;
}

export const upsertArticle = async(record, member) => {
    const articleId = record.article_id ?? '';
    const title = record.article_title ?? '';
    const content = encodeURIComponent(record.article_content) ?? '';
    const dateCreated = record.article_datecreated ? getTimestamp(record.article_datecreated) : getTimestamp();
    const isActive = record.article_isactive;   //?.toString()==='true' ? 'true' : 'false';
    const datePublished = record.article_datepublished ? getTimestamp(record.article_datepublished) : isActive ? getTimestamp() : '';
    const permissionId = member.data.permissions[0];

    const resp=await axios(`${funcsDir()}articles_upsert?articleid=${articleId}&title=${title}&content=${content}&datecreated=${dateCreated}&datepublished=${datePublished}&permissionid=${permissionId}&isactive=${isActive}`);
    return resp.data.response;
}

export const getArticles = async(limit) => {
    if(!limit) {
        limit = 1000000;
    }
    
    const resp=await axios(`${funcsDir()}articles_get?limit=${limit}`);
    return resp.data.response;    
};

export const deleteArticle = async(record, sessionState) => {
    const articleId = record[0].article_id;
    const uniqueId = sessionState.data.customFields.uniqueid;
    const sessionId = sessionState.sessionId;
    
    const resp=await axios(`${funcsDir()}article_delete?articleid=${articleId}&uniqueid=${uniqueId}&sessionid=${sessionId}`);
    
    return resp.data.response;
};

export const setCookie = async(cookie, value, maxAge) => {
    const resp=await axios(`${funcsDir()}cookie_set?cookie=${cookie}&value=${value}&maxage=${maxAge}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const getCookie = async(instance, cookie) => {
    let resp;
    try {
        resp=await instance(`${funcsDir()}cookie_get?cookie=${cookie}`)
        .then(function (response) {
            return response;
        })
    }
    catch (ex) {
        resp = ex;
    }    

    return resp;
};

export const deleteCookie = async(cookie) => {
    const resp=await axios(`${funcsDir()}cookie_delete?cookie=${cookie}`)
        .then(function (response) {
            return response;
    })
    
    return resp;
};

export const getNetworkFees = async () => {
    const res=await axios(`${funcsDir()}udf_networkfees`)
        .then(function (response) {
            return response;
    })
    .catch(function (error) {
        return {
            statusCode: 500,
            body: `Error: ${error}`
        }
    });

    return res;   
}

export const getRevenueAllocation = async () => {
    const res=await axios(`${funcsDir()}network_stake_get`)
        .then(function (response) {
            return response;
    })

    return res;   
}

export const getAccountGroupSupply = async () => {
    const resp=await axios(`${funcsDir()}hbar_supply_get`);
    return resp.data.balances;
}

export const getAccountsByGroup = async(member, sessionId) => {
    const resp=await axios(`${funcsDir()}accounts_by_group_get?sessionid=${sessionId}&permissions=${member.permissions[0]}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const getAccountGroups = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=accountgroups`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const saveAccountGroup = async(ag) => {
    const accountGroupId = ag.accountgroupid;
    const accountGroupName = ag.accountgroupname;
    const resp=await axios(`${funcsDir()}account_group_upsert?accountgroupid=${accountGroupId}&accountgroupname=${accountGroupName}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const saveFeedback = async(comment, section, memberid) => {
    const resp=await axios(`${funcsDir()}feedback_insert?msmemberid=${memberid}&comment=${comment}&section=${section}`)
         .then(function (response) {
             return response;
    })

    return resp;
};

export const saveAccount = async(account) => {
    const accountId = account.accountid;
    const accountGroupId = account.accountgroupid;
    const shard = account.shard;
    const realm = account.realm;
    const num = account.num;
    const alias = account.alias;

    const resp=await axios(`${funcsDir()}account_upsert?accountid=${accountId}&accountgroupid=${accountGroupId}&shard=${shard}&realm=${realm}&num=${num}&alias=${alias}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteAccount = async(accountId) => {
    const resp=await axios(`${funcsDir()}account_delete?accountid=${accountId}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteAccountGroup = async(accountGroupId) => {
    const resp=await axios(`${funcsDir()}account_group_delete?accountgroupid=${accountGroupId}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteUserAccount = async(userId, memberAccountId) => {
    const resp=await axios(`${funcsDir()}user_account_delete?memberaccountid=${memberAccountId}&userid=${userId}`)
        .then(function (response) {
            return response;
    })

    return resp;
};
export const upsertUserAccount = async (userId, rec) => {
    const memberAccountId=rec.memberaccountid ? rec.memberaccountid : '';
    const shard=rec.shard;
    const realm=rec.realm;
    const num=rec.num;
    const paramStr=`memberaccountid=${memberAccountId}&userid=${userId}&shard=${shard}&realm=${realm}&num=${num}`
    
    const res=axios(`${funcsDir()}user_account_upsert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getUserAccounts = async (userId, sessionId='') => {
    const res=axios(`${funcsDir()}user_accounts_get?userid=${userId}&sessionid=${sessionId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getAccountsRewards = async (member, sessionId) => {
    const uniqueid = member.customFields.uniqueid;
    const res=axios(`${funcsDir()}accounts_rewards_get?uniqueid=${uniqueid}&sessionid=${sessionId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const insertPublishedArticleDms = async (articleId, uniqueId, sessionId) => {
    let paramStr=`uniqueid=${uniqueId}&sessionid=${sessionId}&articleid=${articleId}`;
    
    const res=await axios(`/.netlify/functions/member_dms_article_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, paramStr);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const upsertAdminData = async(record) => {
    const dataName = record.dataname;
    const dataValue = record.datavalue;
    
    const resp=await axios(`${funcsDir()}admin_data_upsert?dataname=${dataName}&datavalue=${dataValue}`)
        .then(function (response) {
            return response;
    })

    return resp;
}

export const getUserSession = async (sessionId, userId) => {
    //console.log('getUserSession', sessionId, userId);
    
    const res=axios(`${funcsDir()}session_get?sessionid=${sessionId}&userid=${userId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}


export const getUserSessions = async (userId) => {
    const res=axios(`${funcsDir()}sessions_get?userid=${userId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getLatency = async () => {
    const res=axios(`${funcsDir()}latency_get`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const refreshToken = async (token) => {
    const res=axios.post(`${funcsDir()}auth_token_refresh`, { token })
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const devlogin = async () => {
    const res=axios(`${funcsDir()}devlogin`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const trackEvent = (location, sessionId, trackId, resourceId) => {
    const sessionUuid = sessionId ? sessionId : '';
    const trackUuid = trackId ? trackId : '';
    const resourceUuid = resourceId ? resourceId : '';
    const browser =  getBrowserIdentity() ?? '';

    const res=axios(`${funcsDir()}appevent_insert?location=${location}&sessionuuid=${sessionUuid}&trackuuid=${trackUuid}&resourceuuid=${resourceUuid}&browser=${browser}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

    return res;
}

export const logError = async (errorCode, errorMessage, errorDetail, userId, sessionId) => {
    
    if(!sessionId) {
        sessionId=localStorage.getItem('__sak:session') ?? '';
    }

    const paramStr=`errorCode=${errorCode}&errorMessage=${errorMessage}&errorDetail=${errorDetail}&userId=${userId}&sessionid=${sessionId}`
    const res=axios(`${funcsDir()}error_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    return res;
}

export const send = async(subject, html) => {
    const res=axios(`${funcsDir()}email_send?subject=${subject}&html=${html}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

        return res;
}

export const unsubscribe = async(email) => {
    const res=axios(`${funcsDir()}unsubscribe?email=${email}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

        return res;
}

export const fetchAccessToken = async() => {
    const res=await axios(`/.netlify/functions/pbi_access_token_get`)
        .then(function (response) {
            return response;
        })   

    return res;
};

export const fetchEmbedToken = async(groupId, reportId, accessToken) => {
    const res=await axios(`/.netlify/functions/pbi_embed_token_get?groupid=${groupId}&reportid=${reportId}&accesstoken=${accessToken}`)
        .then(function (response) {
            return response;
        })

    return res.data.token;

};